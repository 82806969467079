import { useSelector } from '@/api/state';
import {
  PurchaseOrder,
  PurchaseOrderCityPlumbing,
  PurchaseOrderIndirect,
} from '@/gql/graphql';
import { getUserRoles } from '@/utils/stateAccessors';
import CurrencyValue from '@payaca/components/currencyValue/CurrencyValue';
import Badge from '@payaca/components/plBadge/Badge';
import { TTableRowAction } from '@payaca/components/plManageableItemsList/components/Table';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { PurchaseOrderCityPlumbingStatus } from '@payaca/types/materialsListTypes';
import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  getCityPlumbingPurchaseOrderBadgeConfig,
  getIndirectPurchaseOrderBadgeConfig,
  PurchaseOrderBadgeConfig,
} from '../../../helpers/purchaseOrders';
import ConfirmPurchaseOrderModal from './ConfirmPurchaseOrderModal';

type PoForList = (
  | Pick<PurchaseOrderIndirect, '__typename' | 'id' | 'reference' | 'status'>
  | Pick<
      PurchaseOrderCityPlumbing,
      '__typename' | 'id' | 'reference' | 'status' | 'cityPlumbingOrder'
    >
) & {
  supplier: Pick<PurchaseOrder['supplier'], 'name'>;
  totals: Pick<
    PurchaseOrder['totals'],
    'predictedValueExcTax' | 'purchasedValueExcTax'
  >;
  project?:
    | (Pick<NonNullable<PurchaseOrder['project']>, 'id' | 'reference'> & {
        customer?: Pick<
          NonNullable<NonNullable<PurchaseOrder['project']>['customer']>,
          'id' | 'name'
        > | null;
      })
    | null;
};

export const PurchaseOrdersManageableItemsListTable: FC<{
  purchaseOrders: PoForList[];
  includedColumns?: (
    | 'ref'
    | 'status'
    | 'supplier'
    | 'project'
    | 'project-customer'
    | 'forecast-cost'
    | 'purchase-cost'
  )[];
  isLoading?: boolean;
  returnToProjectOnBackNav?: boolean;
  onUpdateSuccess?: () => void;
}> = ({
  purchaseOrders,
  includedColumns,
  isLoading,
  returnToProjectOnBackNav = false,
  onUpdateSuccess,
}) => {
  const history = useHistory();
  const userRoles = useSelector(getUserRoles);

  const [confirmPurchaseOrderModal, setConfirmPurchaseOrderModal] = useState<
    number | null
  >(null);

  const getItemActions = (item: PoForList) => {
    const actions: TTableRowAction<PoForList>[] = [];

    if (
      item.status === 'SENT' &&
      userHasRequiredPermission(userRoles, [
        MaterialsListPermissions.CONFIRM_PURCHASE_ORDER,
      ])
    ) {
      actions.push({
        label: 'Confirm purchases',
        onClick: () => setConfirmPurchaseOrderModal(+item.id),
      });
    }

    return actions;
  };

  return (
    <>
      <ManageableItemsList.Table
        items={purchaseOrders}
        uniqueKey="id"
        onClickRow={(row) =>
          history.push(
            `/purchaseOrders/${row.id}${
              returnToProjectOnBackNav && row.project?.id
                ? `?projectId=${row.project.id}`
                : ''
            }`
          )
        }
        isLoading={isLoading}
        itemActions={getItemActions}
      >
        {(!includedColumns?.length || includedColumns.includes('ref')) && (
          <ManageableItemsList.Table.Column<PoForList, 'reference'>
            header="PO"
            field="reference"
          />
        )}

        {(!includedColumns?.length || includedColumns.includes('status')) && (
          <ManageableItemsList.Table.Column<PoForList, 'status'>
            header="Status"
            field="status"
            render={(status, row) => {
              let badgeConfig: PurchaseOrderBadgeConfig;
              if (row.__typename === 'PurchaseOrderCityPlumbing') {
                const status = row.cityPlumbingOrder?.status
                  ?.toLowerCase()
                  .replaceAll('_', '-') as PurchaseOrderCityPlumbingStatus;
                badgeConfig = getCityPlumbingPurchaseOrderBadgeConfig(status);
              } else {
                badgeConfig = getIndirectPurchaseOrderBadgeConfig(status);
              }
              return (
                <Badge
                  colour={badgeConfig.colour}
                  variant={badgeConfig.variant}
                >
                  {badgeConfig.label}
                </Badge>
              );
            }}
          />
        )}

        {(!includedColumns?.length || includedColumns.includes('project')) && (
          <ManageableItemsList.Table.Column<PoForList, 'project'>
            header="Project"
            field="project"
            render={(project) => {
              if (!project) return null;
              return (
                <Link
                  onClick={(e) => e.stopPropagation()}
                  to={`/deals/${project.id}`}
                >
                  #{project?.reference}
                </Link>
              );
            }}
          />
        )}

        {(!includedColumns?.length ||
          includedColumns.includes('project-customer')) && (
          <ManageableItemsList.Table.Column<PoForList, 'project'>
            header="Customer"
            field="project"
            render={(project) => {
              if (!project?.customer) return null;
              return (
                <Link
                  to={`/customers/${project.customer.id}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {project.customer.name}
                </Link>
              );
            }}
          />
        )}

        {(!includedColumns?.length || includedColumns.includes('supplier')) && (
          <ManageableItemsList.Table.Column<PoForList, 'supplier'>
            header="Supplier"
            field="supplier"
            render={(supplier) => {
              return (
                <Badge colour="gray" variant="soft">
                  {supplier.name}
                </Badge>
              );
            }}
          />
        )}

        {(!includedColumns?.length ||
          includedColumns.includes('forecast-cost')) && (
          <ManageableItemsList.Table.Column<PoForList, 'totals'>
            className="text-right"
            header="Forecast Cost (ex VAT)"
            field="totals"
            render={(totals) => {
              return <CurrencyValue {...totals.predictedValueExcTax} />;
            }}
          />
        )}

        {(!includedColumns?.length ||
          includedColumns.includes('purchase-cost')) && (
          <ManageableItemsList.Table.Column<PoForList, 'totals'>
            className="text-right"
            header="Purchase Cost (ex VAT)"
            field="totals"
            render={(totals) => {
              if (!totals.purchasedValueExcTax) {
                return null;
              }

              return <CurrencyValue {...totals.purchasedValueExcTax} />;
            }}
          />
        )}
      </ManageableItemsList.Table>
      <ConfirmPurchaseOrderModal
        purchaseOrderId={confirmPurchaseOrderModal}
        isOpen={!!confirmPurchaseOrderModal}
        onClose={() => setConfirmPurchaseOrderModal(null)}
        onConfirmPurchaseOrderSuccess={() => {
          setConfirmPurchaseOrderModal(null);
          onUpdateSuccess?.();
        }}
      />
    </>
  );
};
