import useGetEmailTemplates from '@/api/queries/emailTemplates/useGetEmailTemplates';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import { AutomationEntityTypes } from '@payaca/types/automationTypes';
import { checkUnreachable } from '@payaca/utilities/guards';
import { FC } from 'react';

export interface IProps {
  automationEntityType: AutomationEntityTypes;
  value?: string;
  onChange?: (value: { [key: string]: string }) => void;
  onTouch?: (fieldName: string) => void;
}

const generateAutomationType = (
  automationEntityType: AutomationEntityTypes
) => {
  switch (automationEntityType) {
    case 'Estimate':
      return 'AUTOMATION_TEMPLATE_ESTIMATE';
    case 'Quote':
      return 'AUTOMATION_TEMPLATE_QUOTE';
    case 'Invoice':
    case 'Invoice-v2':
      return 'AUTOMATION_TEMPLATE_INVOICE';
    case 'Event':
      return 'AUTOMATION_TEMPLATE_EVENT';
    case 'Deal':
      return 'AUTOMATION_TEMPLATE_PROJECT';
    case 'Proposal':
      return 'AUTOMATION_TEMPLATE_PROPOSAL';
    default:
      checkUnreachable(automationEntityType);
  }
};

const EmailTemplateDropdownField: FC<IProps> = (props) => {
  const { automationEntityType, value, onChange, onTouch } = props;

  const { data, isLoading } = useGetEmailTemplates({
    types: [generateAutomationType(automationEntityType)!],
  });

  const handleOnChange = ({
    emailTemplatePublicId,
  }: {
    [value: string]: any;
  }) => {
    onChange?.({
      emailTemplatePublicId:
        emailTemplatePublicId === 'standard'
          ? undefined
          : emailTemplatePublicId,
    });
  };

  const emailTemplateOptions = (data?.emailTemplates || [])
    .filter((template) => !!template)
    .map((template) => ({
      label: template.name,
      value: template.id,
    }));

  const nonExistantTemplateSelected =
    value && !emailTemplateOptions.find((t) => t.value === value); // template found but not in the list (presumably archived)

  if (
    isLoading ||
    (!data?.emailTemplates.length && !nonExistantTemplateSelected) // no templates and no template archived template
  ) {
    return null;
  }

  return (
    <>
      <DropdownField
        label={'Email Template'}
        name={`emailTemplatePublicId`}
        value={value || 'standard'}
        options={[
          {
            label: 'Standard',
            value: 'standard',
          },
          ...emailTemplateOptions,
          ...(nonExistantTemplateSelected
            ? [
                {
                  label: 'Unknown Template',
                  value: value,
                },
              ]
            : []),
        ]}
        onChange={handleOnChange}
        onTouch={onTouch}
      />
      {nonExistantTemplateSelected && (
        <Alert className="mt-1" colour={EAlertColour.SOFT_RED}>
          Email Template no longer exists, please select another.
        </Alert>
      )}
    </>
  );
};

export default EmailTemplateDropdownField;
