import useCreateCustomField from '@/api/mutations/custom-field-groups/useCreateCustomField';
import useCreateCustomFieldWithinFieldset from '@/api/mutations/custom-field-groups/useCreateCustomFieldWithinFieldset';
import useUpdateCustomField from '@/api/mutations/custom-field-groups/useUpdateCustomField';
import useUpdateCustomFieldWithinFieldset from '@/api/mutations/custom-field-groups/useUpdateCustomFieldWithinFieldset';
import meKeys from '@/api/queries/me/keyFactory';
import { useGetMyProjectCustomFieldGroup } from '@/api/queries/me/useGetMyCustomFieldGroups';
import { CreateCustomFieldInput } from '@/gql/graphql';
import CreateEditCustomFieldFieldset from '@/ui/components/customFieldGroupControl/components/CreateEditCustomFieldFieldset';
import {
  registerFieldBuilder,
  zodHumanFriendlyFormErrorMap,
} from '@/utils/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Conditional from '@payaca/components/conditional/Conditional';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import EmptyState from '@payaca/components/plEmptyState/EmptyState';
import Field, { ValidationMessages } from '@payaca/components/plField/Field';
import Fieldset from '@payaca/components/plFieldset/Fieldset';
import RadioGroupField from '@payaca/components/plGroupField/RadioGroupField';
import Sidebar from '@payaca/components/plSidebar/Sidebar';
import { useToastContext } from '@payaca/components/plToast/ToastContext';
import { clstx } from '@payaca/components/utils';
import { INTERNAL_IDENTIFIER_VALIDATION_REGEX } from '@payaca/custom-fields/constants/regex';
import {
  CustomFieldDefinition,
  CustomFieldType,
  FieldsetCustomFieldDefinition,
  SelectCustomFieldDefinition,
} from '@payaca/custom-fields/types/index';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { z } from 'zod';

export type FormValues<T = CustomFieldType> = {
  type: T;
  identification: {
    label: string;
    identifier: string;
  };
  options?: string[];
  selectedFieldset?: string;
  children?: FormValues<Exclude<T, 'fieldset'>>[];
};

const IDENTIFICATION_SCHEMA = z.object({
  identification: z.object({
    label: z.string().min(1),
    identifier: z
      .string()
      .min(1)
      .regex(
        INTERNAL_IDENTIFIER_VALIDATION_REGEX,
        'Some characters are not allowed, for example: \\ / ? : * " < > | _ -'
      ),
  }),
});

const SIMPLE_CUSTOM_FIELD_SCHEMA = IDENTIFICATION_SCHEMA.extend({
  type: z.enum([
    'textarea',
    'duration',
    'multi-upload',
    'text',
    'email',
    'url',
    'boolean',
    'number',
  ]),
});

const SELECT_CUSTOM_FIELD_SCHEMA = IDENTIFICATION_SCHEMA.extend({
  type: z.literal('select'),
  options: z.array(z.string()).min(1),
});

const CUSTOM_FIELD_SCHEMA = z.discriminatedUnion('type', [
  SIMPLE_CUSTOM_FIELD_SCHEMA,
  SELECT_CUSTOM_FIELD_SCHEMA,
]);

const FIELDSET_CUSTOM_FIELD_SCHEMA = IDENTIFICATION_SCHEMA.extend({
  type: z.literal('fieldset'),
  children: z
    .array(CUSTOM_FIELD_SCHEMA)
    .min(1)
    .max(20)
    .refine((items) => {
      const identifiers = items
        .map((item) => item.identification.identifier)
        .filter((x) => !!x);

      return new Set(identifiers).size === identifiers.length;
    }, 'Custom field identifiers must be unique within a fieldset'),
});

const SCHEMA = z.discriminatedUnion('type', [
  SIMPLE_CUSTOM_FIELD_SCHEMA,
  SELECT_CUSTOM_FIELD_SCHEMA,
  FIELDSET_CUSTOM_FIELD_SCHEMA,
]);

export const CUSTOM_FIELD_TYPE_READABLE_NAME_MAP: Record<
  CustomFieldType,
  string
> = {
  textarea: 'Textarea',
  duration: 'Duration',
  'multi-upload': 'Multiple file upload',
  text: 'Text',
  email: 'Email',
  select: 'Drop-down list',
  boolean: 'True/false',
  fieldset: 'Fieldset',
  number: 'Number',
  url: 'URL',
};

export type TCreateEditCustomFieldSidebarContentProps = {
  onFieldsetSelectionChange?: (selected: boolean) => void;
  setIsFormDirty?: (isDirty: boolean) => void;
  onSubmit: (values: FormValues) => void;
  allCurrentIdentifiers: string[];
  defaultValues?: FormValues;
  isProcessing?: boolean;
};

const CreateEditCustomFieldSidebarContent: FC<
  TCreateEditCustomFieldSidebarContentProps
> = (props) => {
  const {
    onFieldsetSelectionChange,
    setIsFormDirty,
    onSubmit,
    allCurrentIdentifiers,
    defaultValues,
    isProcessing,
  } = props;

  const formMethods = useForm<FormValues>({
    defaultValues: defaultValues || {
      type: 'text',
      identification: {},
    },
    resolver: zodResolver(SCHEMA, {
      errorMap: zodHumanFriendlyFormErrorMap,
    }),
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
  } = formMethods;

  const {
    fields,
    append: appendChildField,
    remove: removeChildField,
    replace: replaceChildFields,
  } = useFieldArray({
    name: 'children',
    control,
  });

  const registerField = registerFieldBuilder(errors);

  const type = watch('type');
  const selectedFieldset = watch('selectedFieldset');
  const fieldsetChildren = watch('children');

  const fieldsetChildrenValidation = registerField('children').validationState;

  useEffect(() => {
    onFieldsetSelectionChange?.(type === 'fieldset');

    // If the type is changed to something other than fieldset, remove all fieldset children
    if (type !== 'fieldset') {
      replaceChildFields([]);
    }
  }, [type]);

  useEffect(() => {
    setIsFormDirty?.(isDirty);
  }, [isDirty]);

  const isEditing = !!defaultValues;

  return (
    <FormProvider {...formMethods}>
      <form
        className={clstx(
          'h-full',
          type === 'fieldset' && 'grid grid-cols-2 h-full'
        )}
        onSubmit={handleSubmit(onSubmit, console.error)}
      >
        <div className="p-4 h-full flex flex-col">
          <Fieldset>
            <CreateEditCustomFieldFieldset
              allCurrentIdentifiers={allCurrentIdentifiers}
              isEditing={isEditing}
            />

            <Conditional condition={type === 'fieldset'}>
              <Card
                variant={
                  fieldsetChildrenValidation &&
                  !fieldsetChildrenValidation.isValid
                    ? 'error'
                    : 'default'
                }
                sizeVariant={CardSizeVariant.SM}
              >
                <Card.Body>
                  <Controller
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Field {...registerField('selectedFieldset')}>
                          <div className="flex justify-between mb-4">
                            <Field.Label className="m-0">Fields</Field.Label>

                            <Button
                              className="-mt-2"
                              variant={EBtnVariant.White}
                              size={EBtnSize.Small}
                              onClick={() => {
                                appendChildField({
                                  type: 'text',
                                  identification: {
                                    label: '',
                                    identifier: '',
                                  },
                                });

                                onChange(fields.length.toString());
                              }}
                            >
                              Add field
                            </Button>
                          </div>

                          <RadioGroupField
                            // change type so that `number` can be inferred
                            value={value || ''}
                            onChange={({ selectedFieldset }) => {
                              onChange(selectedFieldset);
                            }}
                            hiddenInput
                            emphasised
                            options={
                              fieldsetChildren?.map((field, index) => {
                                const canRemoveChild =
                                  !isEditing ||
                                  !!defaultValues?.children?.every(
                                    (c) =>
                                      c.identification.identifier !==
                                      field.identification.identifier
                                  );

                                return {
                                  className: clstx(
                                    !!errors.children?.[index]
                                      ?.identification &&
                                      'ring-1 ring-red-500 rounded-lg'
                                  ),
                                  label: (
                                    <div className="flex gap-4 w-full items-center">
                                      <h4>{field.identification.label}</h4>

                                      <h4 className="text-gray-500 ml-auto">
                                        {
                                          CUSTOM_FIELD_TYPE_READABLE_NAME_MAP[
                                            field.type
                                          ]
                                        }
                                      </h4>

                                      {canRemoveChild && (
                                        <Button
                                          size={EBtnSize.XSmall}
                                          variant={EBtnVariant.White}
                                          onClick={() => {
                                            removeChildField(index);

                                            onChange(undefined);
                                          }}
                                        >
                                          Remove
                                        </Button>
                                      )}
                                    </div>
                                  ),
                                  value: index.toString(),
                                };
                              }) || []
                            }
                          />

                          {fieldsetChildren?.length === 0 && (
                            <EmptyState
                              iconName="link-broken-01.3"
                              text="Add fields to this fieldset"
                            />
                          )}
                        </Field>
                      );
                    }}
                    name="selectedFieldset"
                    control={control}
                  />
                </Card.Body>
              </Card>

              <ValidationMessages
                validationState={fieldsetChildrenValidation}
              />
            </Conditional>
          </Fieldset>

          <Button
            className="mt-auto"
            type="submit"
            disabled={!isDirty}
            isProcessing={isProcessing}
          >
            {isEditing ? 'Edit' : 'Create'} custom field
          </Button>
        </div>

        <Conditional condition={type === 'fieldset'}>
          <div className="bg-gray-50 p-4 h-full">
            {fields.map((field, index) => {
              const isEditingChild = !!defaultValues?.children?.find(
                (c) =>
                  c.identification.identifier ===
                  field.identification.identifier
              );

              return (
                <Fieldset
                  key={field.id}
                  className={clstx(
                    selectedFieldset !== index.toString() && 'hidden'
                  )}
                >
                  <CreateEditCustomFieldFieldset
                    allCurrentIdentifiers={allCurrentIdentifiers}
                    isEditing={isEditingChild}
                    fieldsetChildIndex={index}
                  />
                </Fieldset>
              );
            })}

            {typeof selectedFieldset !== 'string' &&
              !!fieldsetChildren &&
              fieldsetChildren.length > 0 && (
                <div className="flex flex-col h-full">
                  <EmptyState
                    className="my-auto"
                    iconName="arrow-block-left.3"
                    text="Select a field to edit"
                  />
                </div>
              )}
          </div>
        </Conditional>
      </form>
    </FormProvider>
  );
};

export interface IProps {
  isOpen: boolean;
  onClose?: () => void;
  customFieldId?: string;
}

const buildDefaultValuesForCustomFieldDefinition = <
  T extends CustomFieldDefinition,
>(
  customFieldDefinition: T
): FormValues<T['type']> => {
  return {
    type: customFieldDefinition.type,
    identification: {
      label: customFieldDefinition.label,
      identifier: customFieldDefinition.identifier,
    },
    options:
      customFieldDefinition instanceof SelectCustomFieldDefinition
        ? (customFieldDefinition.options?.filter((o) => o) as string[])
        : undefined,
  };
};

const CreateEditCustomFieldSidebar: FC<IProps> = (props) => {
  const { isOpen, onClose, customFieldId } = props;

  /**
   * state
   */
  const [isLargeSidebar, setIsLargeSidebar] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const toastContext = useToastContext();

  /**
   * queries
   */
  const queryClient = useQueryClient();
  const { projectCustomFieldGroup, identifiers, customField } =
    useGetMyProjectCustomFieldGroup(customFieldId);

  const customFieldDefinition = customField
    ? CustomFieldDefinition.fromSchema(customField.schema)
    : undefined;

  /**
   * mutations
   */
  const { mutateAsync: createCustomField, isLoading: isCreatingCustomField } =
    useCreateCustomField();
  const { mutateAsync: updateCustomField, isLoading: isEditingCustomField } =
    useUpdateCustomField();
  const {
    mutateAsync: createCustomFieldWithinFieldset,
    isLoading: isCreatingCustomFieldWithinFieldset,
  } = useCreateCustomFieldWithinFieldset();
  const {
    mutateAsync: updateCustomFieldWithinFieldset,
    isLoading: isUpdatingCustomFieldWithinFieldset,
  } = useUpdateCustomFieldWithinFieldset();

  const defaultValues = customFieldDefinition
    ? {
        ...buildDefaultValuesForCustomFieldDefinition(customFieldDefinition),
        children:
          customFieldDefinition instanceof FieldsetCustomFieldDefinition
            ? customFieldDefinition.children?.map((child) =>
                buildDefaultValuesForCustomFieldDefinition(child)
              )
            : undefined,
      }
    : undefined;

  /**
   * callbacks
   */
  const handleSubmit = async (values: FormValues) => {
    if (!projectCustomFieldGroup) {
      return;
    }

    if (customField) {
      await updateCustomField({
        id: customField.id,
        label: values.identification.label,
        options: values.options,
      }).catch(() => {
        toastContext.pushToast({
          variant: 'white',
          icon: 'error',
          message: 'Something went wrong',
        });
      });

      if (values.type === 'fieldset') {
        for (const child of values.children || []) {
          if (
            defaultValues?.children?.find(
              (defaultChild) =>
                defaultChild.identification.identifier ===
                child.identification.identifier
            )
          ) {
            // Update existing custom field
            await updateCustomFieldWithinFieldset({
              fieldsetId: customField.id,
              fieldIdentifier: child.identification.identifier,
              label: child.identification.label,
              options: child.options,
            }).catch(() => {
              toastContext.pushToast({
                variant: 'white',
                icon: 'error',
                message: 'Something went wrong',
              });
            });
          } else {
            // Create new custom field
            await createCustomFieldWithinFieldset({
              fieldsetId: customField.id,
              definition: {
                type: child.type,
                label: child.identification.label,
                identifier: child.identification.identifier,
                additional:
                  child.type === 'select'
                    ? {
                        select: {
                          options: (child.options || []).filter((x) => x),
                        },
                      }
                    : {
                        simple: {},
                      },
              },
            });
          }
        }
      }
    } else {
      let additional: CreateCustomFieldInput['definition']['additional'] = {
        simple: {},
      };

      if (values.type === 'select') {
        additional = {
          select: {
            options: (values.options || []).filter((x) => x),
          },
        };
      }

      if (values.type === 'fieldset') {
        additional = {
          fieldset: {
            children:
              values.children?.map((child) => ({
                type: child.type,
                label: child.identification.label,
                identifier: child.identification.identifier,
                additional:
                  child.type === 'select'
                    ? {
                        select: {
                          options: (child.options || []).filter((x) => x),
                        },
                      }
                    : {
                        simple: {},
                      },
              })) || [],
          },
        };
      }

      await createCustomField({
        groupId: projectCustomFieldGroup.id,
        definition: {
          type: values.type,
          label: values.identification.label,
          identifier: values.identification.identifier,
          additional,
        },
      }).catch(() => {
        toastContext.pushToast({
          variant: 'white',
          icon: 'error',
          message: 'Something went wrong',
        });
      });
    }

    await queryClient.invalidateQueries({
      queryKey: meKeys.customFieldGroups(),
    });

    onClose?.();
  };

  return (
    <Sidebar
      isOpen={isOpen}
      onClose={onClose}
      size={isLargeSidebar ? 'lg' : 'standard'}
      title={`${customFieldId ? 'Edit' : 'Create'} Custom Field`}
      warnUserOnClose={isFormDirty}
      warnUserContent={{
        body: 'The changes to this Custom Field are not saved and will be lost.',
        confirm: 'Close',
      }}
    >
      <Sidebar.Body>
        {!customFieldId || (customFieldId && customFieldDefinition) ? (
          <CreateEditCustomFieldSidebarContent
            onSubmit={handleSubmit}
            onFieldsetSelectionChange={setIsLargeSidebar}
            setIsFormDirty={setIsFormDirty}
            allCurrentIdentifiers={identifiers.all}
            defaultValues={defaultValues}
            isProcessing={
              isCreatingCustomField ||
              isEditingCustomField ||
              isCreatingCustomFieldWithinFieldset ||
              isUpdatingCustomFieldWithinFieldset
            }
          />
        ) : (
          <div>Loading...</div>
        )}
      </Sidebar.Body>
    </Sidebar>
  );
};

export default CreateEditCustomFieldSidebar;
