import useArchiveCustomField from '@/api/mutations/custom-field-groups/useArchiveCustomField';
import meKeys from '@/api/queries/me/keyFactory';
import {
  CustomField,
  CustomFieldGroup,
  MyCustomFieldGroupsQuery,
} from '@/gql/graphql';
import CreateEditCustomFieldSidebar from '@/ui/components/customFieldGroupControl/CreateEditCustomFieldSidebar';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import Modal from '@payaca/components/plModal/Modal';
import Tooltip, {
  TooltipPositionVariant,
} from '@payaca/components/plTooltip/Tooltip';
import {
  CustomFieldDefinition as CustomFieldDefinitionCls,
  CustomFieldType,
  FieldsetCustomFieldDefinition,
  SelectCustomFieldDefinition,
} from '@payaca/custom-fields/types/index';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { FC, useReducer, useState } from 'react';

const CustomFieldTypeReadableNameMap: Record<CustomFieldType, string> = {
  textarea: 'Textarea',
  duration: 'Duration',
  'multi-upload': 'Multiple file upload',
  text: 'Text',
  email: 'Email',
  select: 'Drop-down list',
  boolean: 'True/false',
  fieldset: 'Fieldset',
  number: 'Number',
  url: 'URL',
};

type ModalState = {
  activeModal:
    | 'createCustomField'
    | 'editCustomField'
    | 'archiveCustomField'
    | null;
  customFieldId?: CustomField['id'];
};

type ModalAction =
  | {
      type: 'createCustomField';
    }
  | {
      type: 'editCustomField';
      payload: {
        customFieldId: CustomField['id'];
      };
    }
  | {
      type: 'archiveCustomField';
      payload: {
        customFieldId: CustomField['id'];
      };
    }
  | {
      type: 'closeModal';
    };

const modalReducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case 'createCustomField':
      return { activeModal: 'createCustomField' };
    case 'editCustomField':
      return {
        activeModal: 'editCustomField',
        customFieldId: action.payload.customFieldId,
      };
    case 'archiveCustomField':
      return {
        activeModal: 'archiveCustomField',
        customFieldId: action.payload.customFieldId,
      };
    case 'closeModal':
      return { activeModal: null };
    default:
      return state;
  }
};

export const CustomFieldGroupControl: FC<{
  customFieldGroup: CustomFieldGroup;
  heading?: string;
  subHeading?: string;
}> = ({ customFieldGroup, heading, subHeading }) => {
  const [modalState, modalDispatch] = useReducer(modalReducer, {
    activeModal: null,
  });

  return (
    <div>
      <ManageableItemsList>
        <ManageableItemsList.HeaderBar
          heading={heading || ''}
          subHeading={subHeading}
          buttons={
            <Button
              size={EBtnSize.Small}
              variant={EBtnVariant.White}
              onClick={() =>
                modalDispatch({
                  type: 'createCustomField',
                })
              }
            >
              Add custom field
            </Button>
          }
        />
        <ManageableItemsList.Table
          emptyText="No Custom Fields yet"
          items={customFieldGroup.customFields.filter((x) => !x.archivedAt)}
          uniqueKey={'id'}
          onClickRow={(row) => {
            modalDispatch({
              type: 'editCustomField',
              payload: {
                customFieldId: row.id,
              },
            });
          }}
          itemActions={[
            {
              label: 'Edit',
              onClick: (x) =>
                modalDispatch({
                  type: 'editCustomField',
                  payload: {
                    customFieldId: x.id,
                  },
                }),
            },
            {
              label: 'Delete',
              onClick: (x) =>
                modalDispatch({
                  type: 'archiveCustomField',
                  payload: {
                    customFieldId: x.id,
                  },
                }),
            },
          ]}
        >
          <ManageableItemsList.Table.Column header="Label" field="label" />
          <ManageableItemsList.Table.Column
            header="Type"
            field="type"
            render={(type) =>
              CustomFieldTypeReadableNameMap[type as CustomFieldType]
            }
          />
          {customFieldGroup.customFields.some((x) =>
            ['select'].includes(x.type)
          ) && (
            <ManageableItemsList.Table.Column
              header="Config"
              field="id"
              render={(_, field: CustomFieldGroup['customFields'][0]) => {
                const definition = field.schema
                  ? CustomFieldDefinitionCls.fromSchema(field.schema)
                  : null;

                if (!definition) return;

                if (definition instanceof SelectCustomFieldDefinition) {
                  const options = field.options?.filter((x) => x);

                  return (
                    <div>
                      {options?.length && (
                        <ul className="m-0 list-none p-0">
                          {options.slice(0, 3).map((option, index) => (
                            <li key={`${option}-${index}`}>{option}</li>
                          ))}
                          {options.length > 3 && (
                            <li>
                              <Tooltip
                                positionVariant={TooltipPositionVariant.BOTTOM}
                                tooltipContent={
                                  <ul className="m-0 list-none p-0">
                                    {options
                                      .slice(3, options.length)
                                      .map((option, index) => (
                                        <li key={`${option}-${index}`}>
                                          {option}
                                        </li>
                                      ))}
                                  </ul>
                                }
                              >
                                ...and {options.length - 3} more
                              </Tooltip>
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  );
                }
              }}
            />
          )}
          <ManageableItemsList.Table.Column
            header="Internal Name"
            field="identifier"
          />
        </ManageableItemsList.Table>
      </ManageableItemsList>

      <CreateEditCustomFieldSidebar
        isOpen={
          modalState.activeModal === 'createCustomField' ||
          modalState.activeModal === 'editCustomField'
        }
        customFieldId={modalState.customFieldId}
        onClose={() => modalDispatch({ type: 'closeModal' })}
      />

      <ArchiveCustomFieldModal
        isOpen={modalState.activeModal === 'archiveCustomField'}
        onClose={() => modalDispatch({ type: 'closeModal' })}
        customFieldId={modalState.customFieldId}
      />
    </div>
  );
};

const ArchiveCustomFieldModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  customFieldId?: CustomField['id'];
}> = ({ isOpen, onClose, customFieldId }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const queryClient = useQueryClient();

  const { mutateAsync: archiveCustomField } = useArchiveCustomField();

  const handleArchive = () => {
    if (!customFieldId) {
      return;
    }

    setIsProcessing(true);

    archiveCustomField({
      id: customFieldId,
    })
      .then((result) => {
        updateCustomFieldGroupsQueryData(
          queryClient,
          result.archiveCustomField
        );

        onClose();
      })
      .catch(() => {
        setErrorMessage('An error occurred while deleting this field.');
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Delete a Custom Field'}>
      <Modal.Body>
        <p>You will lose any data set against this field.</p>
      </Modal.Body>
      <Modal.Footer>
        {errorMessage && (
          <Alert colour={EAlertColour.SOFT_RED} className="mb-4">
            {errorMessage}
          </Alert>
        )}
        <Modal.Footer.Actions>
          <Button
            colour={EBtnColour.Red}
            isProcessing={isProcessing}
            onClick={() => !isProcessing && handleArchive()}
          >
            {'Yes, delete'}
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

const updateCustomFieldGroupsQueryData = (
  queryClient: QueryClient,
  updatedCustomFieldGroup: CustomFieldGroup
) => {
  queryClient.setQueryData<MyCustomFieldGroupsQuery>(
    meKeys.customFieldGroups(),
    (old) => {
      if (!old) return;
      return {
        me: {
          user: {
            account: {
              customFieldGroups: old.me.user.account.customFieldGroups.map(
                (x) => {
                  if (x.id === updatedCustomFieldGroup.id) {
                    return updatedCustomFieldGroup;
                  }
                  return x;
                }
              ),
            },
          },
        },
      };
    }
  );
};

const FieldsetCustomFieldConfig: FC<{
  definition: FieldsetCustomFieldDefinition;
}> = ({ definition }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div>
      <pre
        className={`rounded-md bg-gray-100 p-2 text-sm ${
          showMore
            ? ''
            : 'container-bottom-shadow relative max-h-[60px] overflow-hidden'
        }`}
      >
        {JSON.stringify(
          definition.schema,
          (key, value) => {
            if (key === 'metadata') return undefined;
            if (key === '$schema') return undefined;

            return value;
          },
          2
        )}
      </pre>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setShowMore((s) => !s);
        }}
        variant={EBtnVariant.LinkInline}
        size={EBtnSize.Small}
      >
        {showMore ? 'Show less' : 'Show more'}
      </Button>
    </div>
  );
};
