import { FieldValidationState } from '@payaca/components/plField/Field';
import { FieldError, FieldErrors, get } from 'react-hook-form';
import { z, ZodErrorMap } from 'zod';

export const zodHumanFriendlyFormErrorMap: ZodErrorMap = (issue, ctx) => {
  let msg: string | null = null;
  switch (issue.code) {
    case z.ZodIssueCode.too_small:
      if (issue.minimum === 1) msg = 'Required';
      break;
    case z.ZodIssueCode.invalid_type:
      if (issue.received === 'null') msg = 'Required';
      if (issue.expected === 'number' && issue.received === 'nan')
        msg = 'Must be a number';
      if (issue.expected === 'integer' && issue.received === 'float')
        msg = 'Number must be an integer';
      break;
    case z.ZodIssueCode.too_big:
      if (issue.maximum)
        msg = `Must be less than or equal to ${issue.maximum} characters`;
  }
  return { message: msg || ctx.defaultError };
};

type RegisterFieldProps = {
  name: string;
  validationState?: FieldValidationState;
};

/**
 * Returns a function that behaves like react-hook-form's register function, but for our Field component
 */
export const registerFieldBuilder =
  (errors: FieldErrors) =>
  (name: string): RegisterFieldProps => {
    const fieldError = get(errors, name) as FieldError | undefined;

    if (!fieldError || Array.isArray(fieldError)) {
      return {
        name,
      };
    }

    return {
      name,
      validationState: {
        isValid: false,
        validationMessages: [
          fieldError.message ||
            fieldError.root?.message ||
            `Unknown Error: ${fieldError.type}`,
        ],
      },
    };
  };
