import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import Modal from '@payaca/components/plModal/Modal';
import { getHydratedPurchaseOrder } from '@payaca/store/materialsList/materialsListActions';
import { HydratedPurchaseOrder } from '@payaca/types/materialsListTypes';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmPurchaseOrderControl from '../confirmPurchaseOrderControl/ConfirmPurchaseOrderControl';

type Props = {
  purchaseOrderId?: number | null;
  isOpen: boolean;
  onConfirmPurchaseOrderSuccess?: () => void;
  onClose?: () => void;
};
const ConfirmPurchaseOrderModal: FC<Props> = ({
  purchaseOrderId,
  isOpen,
  onConfirmPurchaseOrderSuccess,
  onClose,
}: PropsWithChildren<Props>): JSX.Element => {
  const dispatch = useDispatch();
  const [purchaseOrder, setPurchaseOrder] =
    useState<HydratedPurchaseOrder | null>(null);

  const getPurchaseOrder = () => {
    if (purchaseOrderId) {
      dispatch(
        getHydratedPurchaseOrder.request({
          purchaseOrderId,
          callback: (purchaseOrder) => {
            setPurchaseOrder(purchaseOrder);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      getPurchaseOrder();
    } else {
      setPurchaseOrder(null);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Record purchases'}>
      <Modal.Body>
        {!purchaseOrder ? (
          <MiniLoader />
        ) : (
          <ConfirmPurchaseOrderControl
            purchaseOrder={purchaseOrder}
            onConfirmPurchaseOrderSuccess={onConfirmPurchaseOrderSuccess}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmPurchaseOrderModal;
