import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  CreateCustomFieldWithinFieldsetInput,
  CreateCustomFieldWithinFieldsetMutation,
} from '@/gql/graphql';

const CREATE_CUSTOM_FIELD_WITHIN_FIELDSET_MUTATION = graphql(`
  mutation CreateCustomFieldWithinFieldset(
    $input: CreateCustomFieldWithinFieldsetInput!
  ) {
    createCustomFieldWithinFieldset(input: $input) {
      id
      archivedAt
      identifier
      isRequired
      label
      type
      options
      schema
    }
  }
`);

const useCreateCustomFieldWithinFieldset = (
  options?: UseMutationOptions<
    CreateCustomFieldWithinFieldsetMutation,
    unknown,
    CreateCustomFieldWithinFieldsetInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(CREATE_CUSTOM_FIELD_WITHIN_FIELDSET_MUTATION, {
        input,
      });
    },
    ...options,
  });
};

export default useCreateCustomFieldWithinFieldset;
