import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Modal, { IProps as ModalProps } from '@payaca/components/plModal/Modal';
import { FC } from 'react';

type Props = { onDelete: () => void; bodyText?: string } & Omit<
  ModalProps,
  'title'
>;
const DeleteEmailTemplateModal: FC<Props> = (props) => {
  const {
    isOpen,
    onClose,
    onDelete,
    bodyText = 'Are you sure you want to delete this email template? This action cannot be undone.',
  } = props;
  return (
    <Modal
      zIndexLevel={2}
      isOpen={isOpen}
      title="Delete email template"
      onClose={onClose}
    >
      <Modal.Body>
        <p>{bodyText}</p>
      </Modal.Body>

      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            className="ml-2"
            variant={EBtnVariant.Outline}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            colour={EBtnColour.Red}
            onClick={() => {
              onClose?.();
              onDelete();
            }}
          >
            Delete
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteEmailTemplateModal;
