import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  UpdateCustomFieldWithinFieldsetInput,
  UpdateCustomFieldWithinFieldsetMutation,
} from '@/gql/graphql';

const UPDATE_CUSTOM_FIELD_WITHIN_FIELDSET_MUTATION = graphql(`
  mutation UpdateCustomFieldWithinFieldset(
    $input: UpdateCustomFieldWithinFieldsetInput!
  ) {
    updateCustomFieldWithinFieldset(input: $input) {
      id
      archivedAt
      identifier
      isRequired
      label
      type
      options
      schema
    }
  }
`);

const useUpdateCustomFieldWithinFieldset = (
  options?: UseMutationOptions<
    UpdateCustomFieldWithinFieldsetMutation,
    unknown,
    UpdateCustomFieldWithinFieldsetInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(UPDATE_CUSTOM_FIELD_WITHIN_FIELDSET_MUTATION, {
        input,
      });
    },
    ...options,
  });
};

export default useUpdateCustomFieldWithinFieldset;
