import React, {
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box';
import { twMerge } from 'tailwind-merge';

/**
 *
 * Usage:
 * <Card sizeVariant={CardSizeVariant.MD}>
 *   <Card.Header>Header</Card.Header>
 *   <Card.Body>
 *      <Card.Title>Title</Card.Title>
 *      <Card.Subtitle>Subtitle</Card.Subtitle>
 *      <p>Body text</p>
 *   </Card.Body>
 *   <Card.Footer>Footer</Card.Footer>
 * </Card>
 *
 */

export enum CardSizeVariant {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

const SizeContext = React.createContext<CardSizeVariant>(CardSizeVariant.MD);

export interface Props {
  sizeVariant?: CardSizeVariant;
  variant?: 'default' | 'error';
}

export type CardProps<E extends React.ElementType> = PolymorphicComponentProps<
  E,
  Props
>;

const defaultElement = 'div';

const Card: <E extends React.ElementType = typeof defaultElement>(
  props: CardProps<E>
) => React.ReactNode | null = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {
      children,
      sizeVariant = CardSizeVariant.MD,
      variant = 'default',
      className,
      ...rest
    }: CardProps<E>,
    ref: typeof rest.ref
  ) => {
    return (
      <SizeContext.Provider value={sizeVariant}>
        {/*It seems `react-polymorphic-box` is deprecated. And isn't compatible with typescript v5*/}
        {/*https://github.com/kripod/react-polymorphic-box/issues/26#issuecomment-2014522158*/}
        {/*@ts-expect-error*/}
        <Box
          as={defaultElement}
          className={twMerge(
            'prose flex flex-col rounded-xl border bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:shadow-slate-700/[.7]',
            variant === 'error' && 'border-red-500',
            className
          )}
          ref={ref}
          {...rest}
        >
          {children}
        </Box>
      </SizeContext.Provider>
    );
  }
);

const Header: FunctionComponent = ({ children }: PropsWithChildren) => {
  return (
    <div className="rounded-t-xl border-b bg-gray-100 px-4 py-3 dark:border-gray-700 dark:bg-gray-800 md:px-5 md:py-4">
      <p className="mt-1">{children}</p>
    </div>
  );
};

const Footer: FunctionComponent = ({ children }: PropsWithChildren) => {
  return (
    <div className="rounded-b-xl border-t bg-gray-100 px-4 py-3 dark:border-gray-700 dark:bg-gray-800 md:px-5 md:py-4">
      <p className="mt-1">{children}</p>
    </div>
  );
};

const Title: FunctionComponent<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return <h3 className={className}>{children}</h3>;
};

const Subtitle: FunctionComponent<
  PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  return (
    <p className={'mt-1 uppercase' + (className ? ' ' + className : '')}>
      {children}
    </p>
  );
};

const Body: FunctionComponent<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  const sizeVariant = useContext(SizeContext);

  const sizeClasses = useMemo(() => {
    switch (sizeVariant) {
      case CardSizeVariant.SM:
        return 'md:p-5';
      case CardSizeVariant.LG:
        return 'md:p-10';
      default:
        return 'md:p-7';
    }
  }, [sizeVariant]);

  return (
    <div className={`p-4 ${sizeClasses}` + (className ? ' ' + className : '')}>
      {children}
    </div>
  );
};

export default Object.assign(Card, {
  Body,
  Header,
  Footer,
  Title,
  Subtitle,
});
