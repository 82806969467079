import { ListedFileType } from '@payaca/types/listedFileTypes';
import { ImageFileExtensions, VideoFileExtensions } from './fileHelper';

export const ListedFileTypeMap: { [key in ListedFileType]: string } = {
  image: 'Image',
  pdf: 'PDF',
  form: 'Form',
  video: 'Video',
  other: 'Other',
};

export const getReadableListedFileType = (listedFileType: ListedFileType) =>
  ListedFileTypeMap[listedFileType] || 'Other';

const FileTypes = {
  image: ImageFileExtensions,
  video: VideoFileExtensions,
  pdf: ['.pdf'],
};

/**
 * Get fileType and ext for a url
 */
export const getMediaTypeAndExt = (
  url?: string
): { fileType: ListedFileType; ext?: string } => {
  let ext: string | undefined;

  if (url) {
    const parts = url.split('/');
    const extPart = parts[parts.length - 1];
    const extType = extPart.split('.')[1];
    ext = extType ? extType.toLowerCase() : undefined;
  }

  return { fileType: getMediaTypeFromExt(ext), ext };
};

export const getMediaTypeFromExt = (ext?: string): ListedFileType => {
  const dotExt = `.${ext}`;
  if (ext) {
    if (FileTypes.image.includes(dotExt)) {
      return 'image';
    } else if (FileTypes.pdf.includes(dotExt)) {
      return 'pdf';
    } else if (FileTypes.video.includes(dotExt)) {
      return 'video';
    }
  }

  return 'other';
};

/**
 * Get thumbnail bucket key (images only)
 */
export const getDocumentThumbnailBucketKey = (
  url?: string
): string | undefined => {
  if (url) {
    const { fileType } = getMediaTypeAndExt(url);
    if (fileType === 'image') {
      // document is assumed to have a thumbnail
      return `thumbnail_${url}`;
    }
  }
};

const STORAGE_BASE_URL = 'https://storage.googleapis.com';

export const getDocumentUrlsFromBucketKey = (
  bucketKey?: string
): { url: string; thumbnailUrl?: string } => {
  const thumbnailBucketKey = getDocumentThumbnailBucketKey(bucketKey);

  return {
    url: `${STORAGE_BASE_URL}/${process.env.DOCUMENT_UPLOAD_DATA_BUCKET}/${bucketKey}`,
    thumbnailUrl: thumbnailBucketKey
      ? `${STORAGE_BASE_URL}/${process.env.DOCUMENT_UPLOAD_DATA_BUCKET}/${thumbnailBucketKey}`
      : undefined,
  };
};
