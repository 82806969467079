import Button from '@payaca/components/plButton/Button';
import { EBtnColour } from '@payaca/components/plButton/useButtonClassName';
import Modal, { IProps as ModalProps } from '@payaca/components/plModal/Modal';
import { requestVoidPurchaseOrder } from '@payaca/store/materialsList/materialsListActions';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  purchaseOrderId: number;
  onVoidSuccess?: () => void;
} & Omit<ModalProps, 'title'>;
const VoidPurchaseOrderModal: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { isOpen, onClose, purchaseOrderId, onVoidSuccess } = props;
  const [isVoiding, setIsVoiding] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Void Purchase Order?'}>
      <Modal.Body>
        <p>
          Are you sure you want to void the Purchase Order? This action cannot
          be undone.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            onClick={() => {
              if (isVoiding) return;
              setIsVoiding(true);
              dispatch(
                requestVoidPurchaseOrder(
                  purchaseOrderId,
                  () => {
                    setIsVoiding(false);
                    onVoidSuccess?.();
                  },
                  () => setIsVoiding(false)
                )
              );
            }}
            colour={EBtnColour.Red}
            isProcessing={isVoiding}
          >
            Void
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default VoidPurchaseOrderModal;
